import React from 'react';
import Layout from '../components/layout';

import './event.scss';
import {graphql} from 'gatsby';

import parse from 'html-react-parser';
import Seo from '../components/seo';
import Gallery from '../components/gallery';
import {navigate} from 'gatsby';


const EventPost = ({data}) => {
  let {frontmatter, html} = data.markdownRemark;


  html = html?.split('[[---GALLERY---]]');
  const images = frontmatter?.gallery;

  return (<>
    <Seo title={frontmatter?.title} description={frontmatter?.description}/>
    <Layout headerStyle={'waves'}>
      <a href="#" className={'back-to-events'} onClick={() => {
        navigate(-1);
      }}> Vissza </a>
      <article className="event-post-body">

        <h1>
          {frontmatter.title}
        </h1>
        <p className="event-post-description">
          {frontmatter?.description}
        </p>
        <hr/>
        <p className="event-post-date cursive">
          Közzétéve: {frontmatter.date}
        </p>
        {parse(html[0])}
        {images && (<Gallery images={images}/>)}
        {parse(html[1] ? html[1] : '')}
      </article>
    </Layout>
  </>);
};

export default EventPost;

export const pageQuery = graphql`
    query PagePost($pageId: String!) {
        markdownRemark (id: {eq: $pageId}){
            html
            frontmatter {
                main
                description
                date(formatString: "YYYY MMMM DD.", locale: "hu")
                title
                gallery {
                    text
                    image {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            }
        }
    }
`;